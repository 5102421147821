import React, { FC } from 'react';
import { BacklogItem, CriteriaType } from './types';
import { addRank } from './BacklogModel';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material';

interface ChartProps {
  items: BacklogItem[];
  currentItem: BacklogItem;
  ignoreCriteria: CriteriaType[];
}

export const Chart: FC<ChartProps> = ({ items, currentItem, ignoreCriteria }) => {
  const rankItems = addRank(items, ignoreCriteria);
  const theme = useTheme();
  return (
    <BarChart
      xAxis={[{ scaleType: 'band', data: rankItems.map((x) => x.docName), tickInterval: [currentItem?.docName] }]}
      series={[{ data: rankItems.map((x) => x.rank), color: theme.palette.primary.main }]}
      height={300}
    />
  );
};
