import React from 'react';
import { Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { GET_COMPLETED_REVISIONS_PER_MONTH } from './dashboard.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { GetCompletedRevisionsPerMonthQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';
import { BarChart } from '@mui/x-charts/BarChart';

export function CompletedRevisions() {
  const { data, loading } = useQuery(GET_COMPLETED_REVISIONS_PER_MONTH, { fetchPolicy: 'cache-and-network' });

  return (
    <StyledPaper>
      <Typography style={{ fontSize: 18 }}>Reviderte anvisninger pr måned</Typography>
      {loading && <LoadingSpinner />}
      {data?.revisions && <Chart revisions={data.revisions.filter((x) => x !== undefined && x !== null)} />}
    </StyledPaper>
  );
}

type Revision = NonNullable<Unpacked<GetCompletedRevisionsPerMonthQuery['revisions']>>;
function Chart({ revisions }: { revisions: Revision[] }) {
  const theme = useTheme();
  const relevantRevisions = revisions.filter((x) => moment(x!.completedAt).isAfter(moment().subtract(6, 'month')));
  relevantRevisions.sort((a, b) => moment(a.completedAt).diff(moment(b.completedAt)));
  const distinctMonths: string[] = relevantRevisions
    .map((x) => moment(x.completedAt).format('MMM'))
    .filter((value, index, self) => self.indexOf(value) === index);
  const values = distinctMonths.map((month) => relevantRevisions.filter((x) => moment(x.completedAt).format('MMM') === month).length);

  return (
    <BarChart xAxis={[{ scaleType: 'band', data: distinctMonths }]} series={[{ data: values, color: theme.palette.primary.main }]} height={300} />
  );
}
