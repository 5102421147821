import React, { FC, SyntheticEvent } from 'react';
import {
  FormControlLabel,
  Switch,
  Tooltip,
  Grid2 as Grid,
  Button,
  Stack,
  Autocomplete,
  TextField,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {
  selectBacklogFilter,
  setCategories,
  setShowBfs,
  setShowNewGuides,
  setShowBvn,
  setShowOngoingRevisions,
  setShowPublishedGuides,
} from './filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Person } from '@mui/icons-material';

export const Search: FC<{ allCategories: string[]; myCategories: string[] }> = ({ allCategories, myCategories }) => {
  const { categories, showBfs, showBvn, showNewGuides, showOngoingRevisions, showPublishedGuides } = useSelector(selectBacklogFilter);
  const dispatch = useDispatch();

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined,
  ): void => {
    dispatch(setCategories(value));
  };

  return (
    <Grid container spacing={2}>
      <Tooltip title="Vis aktive anvisninger som ikke er under revisjon">
        <FormControlLabel
          control={<Switch onChange={() => dispatch(setShowPublishedGuides(!showPublishedGuides))} checked={showPublishedGuides} />}
          label="Aktive"
        />
      </Tooltip>
      <Tooltip title="Vis aktive anvisninger under revisjon">
        <FormControlLabel
          control={<Switch onChange={() => dispatch(setShowOngoingRevisions(!showOngoingRevisions))} checked={showOngoingRevisions} />}
          label="Under revisjon"
        />
      </Tooltip>
      <Tooltip title="Vis nye anvisninger (ikke publiserte)">
        <FormControlLabel control={<Switch onChange={() => dispatch(setShowNewGuides(!showNewGuides))} checked={showNewGuides} />} label="Nye" />
      </Tooltip>
      <Tooltip title="Vis anvisninger i Byggforskserien">
        <FormControlLabel control={<Switch onChange={() => dispatch(setShowBfs(!showBfs))} checked={showBfs} />} label="BFS" />
      </Tooltip>
      <Tooltip title="Vis blader i Byggebransjens Våtromsnorm">
        <FormControlLabel control={<Switch onChange={() => dispatch(setShowBvn(!showBvn))} checked={showBvn} />} label="BVN" />
      </Tooltip>
      <Grid>
        <Stack direction="row" spacing={1}>
          <Autocomplete
            size="small"
            multiple
            limitTags={3}
            sx={{ minWidth: 200, maxWidth: 600 }}
            value={categories}
            options={allCategories}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Fagområde"
                slotProps={{
                  input: {
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <InputAdornment position="end" sx={{ position: 'absolute', top: '50%', right: 60, transform: 'translate(0, -50%)' }}>
                          <IconButton size="small" title="Velg mine fagområder" onClick={() => dispatch(setCategories(myCategories))}>
                            <Person fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  },
                }}
              />
            )}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
