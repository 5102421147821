import { GetAllBacklogItemsQuery, GuideStatus, NeedForRevision } from '../__generated__/graphql';
import { CriteriaType, BacklogItem, BacklogItemWithRank } from './types';

export function addRank(items: BacklogItem[], ignoreCriteria: CriteriaType[], invertSort: boolean = false): BacklogItemWithRank[] {
  const rankItems: BacklogItemWithRank[] = [];

  items.forEach((item) => {
    if (item) rankItems.push({ ...item, rank: sumScore(item, ignoreCriteria) });
  });

  rankItems.sort((a, b) => {
    let directionSign = invertSort ? -1 : 1;
    let rankDelta = b.rank - a.rank;
    if (rankDelta !== 0) {
      return rankDelta * directionSign;
    }
    if (a.status !== b.status && a.status === GuideStatus.Planned) {
      return 1 * directionSign;
    }
    if (a.status !== b.status && b.status === GuideStatus.Planned) {
      return -1 * directionSign;
    }
    if (a.docNumber && b.docNumber) {
      if (a.docNumber < b.docNumber) {
        return -1 * directionSign;
      }
      if (a.docNumber > b.docNumber) {
        return 1 * directionSign;
      }
    }

    return 0;
  });

  return rankItems;
}

export function sumScore(item: NonNullable<BacklogItem>, ignoreCriteria: CriteriaType[]): number {
  return Object.keys(item.planningScores!)
    .filter((x) => x !== '__typename' && ignoreCriteria.filter((y) => y === x).length === 0)
    .map((x) => item.planningScores![x].score)
    .reduce((a, b) => a + b, 0);
}

export function getNeedForRevisionValue(needForRevision: NeedForRevision): number | undefined {
  if (!needForRevision) return 0;

  switch (needForRevision) {
    case NeedForRevision.Critical:
      return 1;
    case NeedForRevision.High:
      return 2;
    case NeedForRevision.Medium:
      return 3;
    case NeedForRevision.Low:
      return 4;
    case NeedForRevision.NextTime:
      return 5;
    default:
      return undefined;
  }
}
